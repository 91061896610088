import { Noviet } from '@schranz/models';

export interface NovietenFilter {
  label: string;
  exec: (novieten: Noviet[]) => Noviet[];
  disabled: boolean;
}

export default [
  {
    label: 'Geen',
    exec: (novieten: Noviet[]) => {
      return novieten;
    },
    disabled: false,
  },
  {
    label: 'Ongelezen',
    exec: (novieten: Noviet[]) => {
      return novieten.filter((noviet) => !!noviet.unread);
    },
    disabled: false,
  },
  {
    label: 'Favorieten',
    exec: (novieten: Noviet[]) => {
      return novieten.filter((noviet) => noviet.favourite);
    },
    disabled: false,
  },
] as NovietenFilter[];

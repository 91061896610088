
import { Component } from 'vue';
import Comment from './Comment.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { marked } from 'marked';

export default {
  components: {
    Comment,
  },

  beforeMount() {
    this.$store.dispatch('comments/getComments', {
      novietId: this.noviet.novietId,
    });
  },

  mounted() {
    this.$store.dispatch('novieten/removeUnread', {
      novietId: this.noviet.novietId,
    });
  },

  computed: {
    comments() {
      return (
        this.$store.state.comments.comments[this.noviet.novietId]?.comments ??
        []
      );
    },
    editing() {
      return (
        this.$store.state.comments.comments[this.noviet.novietId]?.editing ?? 0
      );
    },
    markdown() {
      return marked(this.newMessage);
    },
  },

  methods: {
    async postComment() {
      if (!this.newMessage.length) {
        return ElMessage({
          message: 'Je comment is wel erg kort...',
          type: 'warning',
        });
      }
      if (this.newRating === 0) {
        return ElMessage({
          message: 'Je moet wel een rating geven!',
          type: 'warning',
        });
      }

      try {
        this.placingComment = true;
        await this.$store.dispatch('comments/postComment', {
          novietId: this.noviet.novietId,
          comment: this.newMessage,
          rating: this.newRating,
        });
        this.newMessage = '';
        this.newRating = 0;
        ElMessage({
          message: 'Je comment is geplaatst!',
          type: 'success',
        });
      } catch (err) {
        ElMessageBox.alert((err as any).message, 'Er ging iets fout!', {
          confirmButtonText: 'oke',
        });
      } finally {
        this.placingComment = false;
      }
    },
    newMessageKey(key: any) {
      if (key.key !== 'Enter') return;
      this.postComment();
    },
    switchMode() {
      this.mode = this.mode === 'edit' ? 'view' : 'edit';
    },
  },

  watch: {
    comments(newValue) {
      const initialScroll = window.scrollY;
      const initialPageHeight = document.body.scrollHeight;

      console.log(this.commentsShown, newValue);
      if (!this.commentsShown || !this.commentsShown.length)
        return (this.commentsShown = newValue);
      this.commentsShown = newValue;

      this.$nextTick(() => {
        const newPageHeight = document.body.scrollHeight;
        const newScroll = initialScroll + (newPageHeight - initialPageHeight);
        window.scrollTo(0, newScroll);
      });
    },
  },

  data() {
    return {
      commentsShown: null,
      newComment: '',
      newMessage: '',
      newRating: 0,
      placingComment: false,
      mode: 'edit' as 'edit' | 'view',
      ratingColors: {
        1: '#ad3636',
        2: '#dfae27',
        3: '#dde00b',
        4: '#afe223',
        5: '#85e261',
      },
    };
  },

  props: ['noviet'],
} as Component;

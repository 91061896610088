import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "noviet-lock-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Star = _resolveComponent("Star")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_StarFilled = _resolveComponent("StarFilled")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_ForkSpoon = _resolveComponent("ForkSpoon")!
  const _component_KnifeFork = _resolveComponent("KnifeFork")!
  const _component_Unlock = _resolveComponent("Unlock")!
  const _component_Lock = _resolveComponent("Lock")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_badge, null, {
        default: _withCtx(() => [
          (!_ctx.favourite)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 0,
                class: "noviet-favourite-icon noviet-icon",
                size: 30,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.favourite = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Star)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.favourite)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 1,
                class: "noviet-favourite-icon isFavourite noviet-icon",
                size: 30,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.favourite = false))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StarFilled)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_badge, {
        value: _ctx.noviet.lunches,
        type: _ctx.noviet.lunch ? 'primary' : 'info'
      }, {
        default: _withCtx(() => [
          (!_ctx.lunch)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 0,
                class: "noviet-lunch-icon noviet-icon",
                size: 30,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.lunch = true))
              }, {
                default: _withCtx(() => [
                  (_ctx.useSpoon)
                    ? (_openBlock(), _createBlock(_component_ForkSpoon, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_KnifeFork, { key: 1 }))
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.lunch)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 1,
                class: "noviet-lunch-icon isLunch noviet-icon",
                size: 30,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.lunch = false))
              }, {
                default: _withCtx(() => [
                  (_ctx.useSpoon)
                    ? (_openBlock(), _createBlock(_component_ForkSpoon, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_KnifeFork, { key: 1 }))
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value", "type"]),
      _createVNode(_component_el_badge, {
        value: _ctx.noviet.locks,
        class: "noviet-lock-badge",
        type: 
          _ctx.noviet.locks === 0
            ? 'info'
            : _ctx.noviet.locks <= 2
            ? 'success'
            : _ctx.noviet.locks <= 4
            ? 'warning'
            : 'danger'
        
      }, {
        default: _withCtx(() => [
          (!_ctx.locked && _ctx.noviet.locks < 5)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 0,
                class: "noviet-unlocked noviet-lock-icon noviet-icon",
                size: 30,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.locked = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Unlock)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.locked || _ctx.noviet.locks >= 5)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 1,
                class: "noviet-locked noviet-lock-icon noviet-icon",
                size: 30,
                disabled: _ctx.noviet.locks >= 5,
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.locked = false))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Lock)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value", "type"])
    ])
  ]))
}

import { Component } from '@vue/runtime-core';
import { nextTick } from 'vue';
import { mapState } from 'vuex';
import { patchGroup } from '../composables/group';
import { deleteEvent, postEvent } from '../composables/event';

export default {
  computed: {
    ...mapState('authorization', ['user', 'role']),
    eventSelectVisible() {
      return this.eventsInputVisible || !this.noviet.events?.length;
    },
    eventsInput: {
      get() {
        return this.noviet.events;
      },
      set(newValue: string[]) {
        console.log({ newValue, old: this.noviet.events });
        const newEvent = newValue.find(
          (event: string) => !this.noviet.events.includes(event)
        );
        const deletedEvent = this.noviet.events.find(
          (event: string) => !newValue.includes(event)
        );

        if (newEvent) this.postEvent(newEvent);
        if (deletedEvent) this.deleteEvent(deletedEvent);

        this.noviet.events = newValue;
      },
    },
  },

  props: ['noviet'],

  watch: {
    'noviet.group'(newValue) {
      this.groupInputVisible = false;
      console.log(newValue);
      patchGroup(this.noviet.novietId, newValue);
    },
  },

  methods: {
    showGroupInput() {
      if (!['mod', 'admin'].includes(this.role)) return;
      this.groupInputVisible = true;
      nextTick(() => {
        document.getElementById('#groupTag')?.focus();
      });
    },
    hideGroupInput() {
      this.groupInputVisible = false;
    },
    showEventsInput() {
      if (!['mod', 'admin'].includes(this.role)) return;
      if (this.eventsInputVisible) return (this.eventsInputVisible = false);

      this.eventsInputVisible = true;
      nextTick(() => {
        document.getElementById('#eventsTag')?.focus();
      });
    },
    postEvent(event: string) {
      this.eventsInputVisible = false;
      console.log(`Posting event ${event}`);
      return postEvent(this.noviet.novietId, event);
    },
    deleteEvent(event: string) {
      this.eventsInputVisible = false;
      console.log(`Deleting event ${event}`);
      return deleteEvent(this.noviet.novietId, event);
    },
  },

  data() {
    return {
      groupInputVisible: false,
      eventsInputVisible: false,
      groupColors: {
        afgevallen: 'danger',
        slotje: 'danger',
        'afschrijven te vroeg': 'warning',
        prima: 'primary',
        enthousiast: 'success',
        'te weinig informatie': 'info',
        geen: 'info',
      },
      groups: ['slotje', 'afgevallen', 'geen'],
      events: [
        'ColAc1',
        'ColAc2',
        'ColAc3',
        'Lunch 5/9',
        'Lunch 6/9',
        'Lunch 9/9',
        'Lunch 10/9',
        'Lunch 11/9',
        'Lunch 12/9',
        'Lunch 13/9',
      ],
    };
  },
} as Component;

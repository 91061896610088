import { store } from '../main';
import router from '../router/router';

export function onMessage(data: any) {
  const { type, payload } = data;

  switch (type) {
    case 'auth': {
      const { success } = payload;

      if (success) {
        console.log('AUTH SUCCESS');
      } else {
        console.error('AUTH FAILED');
      }
      break;
    }
    case 'novieten-forum/update-noviet': {
      const noviet = payload;
      console.log('UPDATE_NOVIET', noviet.novietId);

      store.dispatch('novieten/setNoviet', noviet);

      break;
    }
    case 'novieten-forum/update-comments': {
      const { novietId } = payload;
      console.log('UPDATE_COMMENTS', novietId);

      const currentNovietId = router.currentRoute.value.params.novietId;
      if (currentNovietId != novietId) return;

      store.dispatch('comments/getComments', { novietId });

      break;
    }
  }
}

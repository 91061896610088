import { Module } from 'vuex';
import { deleteFavourite, postFavourite } from '../composables/favourite';
import { deleteLock, postLock } from '../composables/lock';
import { deleteLunch, postLunch } from '../composables/lunch';
import { getNovieten } from '../composables/novieten';

export default {
  namespaced: true,

  state: {
    novieten: [],
  },

  mutations: {
    setNoviet(state, noviet) {
      const index = state.novieten.findIndex(
        (novietInState: any) => novietInState.novietId === noviet.novietId
      );
      if (index === -1) {
        state.novieten.push(noviet);
      } else {
        state.novieten[index] = noviet;
      }
    },
    updateNoviet(state, { novietId, ...update }) {
      const index = state.novieten.findIndex(
        (novietInState: any) => novietInState.novietId === novietId
      );
      if (index === -1) return;
      state.novieten[index] = { ...state.novieten[index], ...update };
    },
    setNovieten(state, novieten) {
      state.novieten = novieten;
    },
    setFavourite(state, { novietId, toFavourite }) {
      state.novieten.find(
        (noviet: any) => noviet.novietId === novietId
      ).favourite = toFavourite;
    },
    setLock(state, { novietId, toLock }) {
      const noviet = state.novieten.find(
        (noviet: any) => noviet.novietId === novietId
      );
      noviet.locked = toLock;
      noviet.locks += toLock ? 1 : -1;
    },
    setLunch(state, { novietId, toLunch }) {
      const noviet = state.novieten.find(
        (noviet: any) => noviet.novietId === novietId
      );
      noviet.lunch = toLunch;
      noviet.lunches += toLunch ? 1 : -1;
    },
  },

  actions: {
    setNoviet({ commit }, noviet) {
      commit('setNoviet', noviet);
    },
    removeUnread({ commit }, { novietId }) {
      commit('updateNoviet', { novietId, unread: 0 });
    },
    async updateNovieten({ commit }) {
      console.log('[Store] update novieten triggered');
      try {
        const novieten = await getNovieten();
        for (const noviet of novieten) {
          if (!noviet.pitch) continue;
          noviet.pitch = noviet.pitch.replace(/\r\n/g, '<br>');
        }
        commit('setNovieten', novieten);
        console.table(novieten);
      } catch (err) {
        console.error('[Store] update novieten error', err);
      }
    },
    async favourite({ commit }, { novietId, toFavourite }) {
      commit('setFavourite', { novietId, toFavourite });
      try {
        if (toFavourite) {
          await postFavourite(novietId);
        } else {
          await deleteFavourite(novietId);
        }
      } catch (err) {
        console.error(err);
        commit('setFavourite', { novietId, toFavourite: !toFavourite });
        throw err;
      }
    },
    async lock({ commit }, { novietId, toLock }) {
      commit('setLock', { novietId, toLock });
      try {
        if (toLock) {
          await postLock(novietId);
        } else {
          await deleteLock(novietId);
        }
      } catch (err) {
        console.error(err);
        commit('setFavourite', { novietId, toLock: !toLock });
        throw err;
      }
    },
    async lunch({ commit }, { novietId, toLunch }) {
      commit('setLunch', { novietId, toLunch });
      try {
        if (toLunch) {
          await postLunch(novietId);
        } else {
          await deleteLunch(novietId);
        }
      } catch (err) {
        console.error(err);
        commit('setLunch', { novietId, toLunch: !toLunch });
        throw err;
      }
    },
  },
} as Module<any, any>;

import { Comment, CommentData } from '@schranz/models';
import { SCHRANZ_API_URL } from '../config';
import { store } from '../main';
import { request, RequestData } from './request';

export async function getComments(
  novietId: number
): Promise<(Comment & { canEdit: boolean })[]> {
  const payload: RequestData = {
    method: 'get',
    url: `${SCHRANZ_API_URL}/forum/comments/${novietId}`,
  };

  const { data } = await request(payload);
  const comments = addCanEditToComments(data);

  return comments;
}

function addCanEditToComments(comments: Comment[]) {
  const username = store.state.authorization.user.username;
  return comments.map((comment) => ({
    ...comment,
    canEdit: username === comment.username,
  }));
}

export async function postComment(
  novietId: number,
  comment: string,
  rating: number
): Promise<void> {
  const payload: RequestData = {
    method: 'post',
    url: `${SCHRANZ_API_URL}/forum/comments/${novietId}`,
    data: {
      comment,
      rating,
    },
  };

  await request(payload);
}

export async function patchComment(
  commentId: number,
  comment: string,
  rating: number
): Promise<Partial<CommentData> & { canEdit: boolean }> {
  const payload: RequestData = {
    method: 'patch',
    url: `${SCHRANZ_API_URL}/forum/comments`,
    data: {
      commentId,
      comment,
      rating,
    },
  };

  const { data } = await request(payload);
  const [updated] = addCanEditToComments([data]);

  return updated;
}

import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ElementPlus from 'element-plus';
import VueMobileDetection from 'vue-mobile-detection';
import App from '../../novieten-forum/src/App.vue';
import router from './router/router';
import 'element-plus/theme-chalk/dark/css-vars.css';
import { createStore } from 'vuex';
import authorizationModule from './modules/authorization.store';
import novietenModule from './modules/novieten.store';
import commentsModule from './modules/comments.store';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

export type loginStatus = {
  statusCode: number;
  success: boolean;
  username?: string;
  error?: string;
};

export const store = createStore({
  modules: {
    authorization: authorizationModule,
    novieten: novietenModule,
    comments: commentsModule,
  },
});

const app = createApp(App);
app.use(router);
app.use(VueAxios, axios);
app.use(ElementPlus);
app.use(store);
app.use(VueMobileDetection);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount('#app');

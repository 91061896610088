import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';

export type LoginData = {
  username: string;
  password: string;
  remember: boolean;
};

export async function login({
  username,
  password,
  remember,
}: LoginData): Promise<Record<string, any>> {
  const payload: RequestData = {
    method: 'post',
    url: `${SCHRANZ_API_URL}/auth/login`,
    data: {
      username,
      password,
    },
  };

  const { data, status } = await request(payload);
  const { jwt } = data;
  if (!jwt) {
    console.error('No JWT', status);
    throw new Error('[login] Expected JWT after login');
  }

  if (remember) window.localStorage.setItem('schranz_jwt', jwt);
  else window.sessionStorage.setItem('schranz_jwt', jwt);

  return data;
}

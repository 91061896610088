import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';
import { Username, JWT } from '@schranz/models';

export async function getUser(): Promise<Username> {
  const payload: RequestData = {
    method: 'get',
    url: `${SCHRANZ_API_URL}/user`,
  };

  const { data } = await request(payload);
  return data;
}

export function getJWT(): JWT | null {
  return (
    window.localStorage.getItem('schranz_jwt') ??
    window.sessionStorage.getItem('schranz_jwt')
  );
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_ProfileCardActions = _resolveComponent("ProfileCardActions")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_ProfileCardInfo = _resolveComponent("ProfileCardInfo")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { id: "noviet-header" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.noviet.name), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      (!_ctx.$isMobile())
        ? (_openBlock(), _createBlock(_component_el_container, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_aside, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_avatar, {
                    fit: "cover",
                    shape: "square",
                    size: 250,
                    src: `data:image/jpeg;base64, ${_ctx.noviet.image}`
                  }, null, 8 /* PROPS */, ["src"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_main, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, { gutter: 10 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ProfileCardActions, { noviet: _ctx.noviet }, null, 8 /* PROPS */, ["noviet"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_el_divider),
                      _createVNode(_component_el_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ProfileCardInfo, { noviet: _ctx.noviet }, null, 8 /* PROPS */, ["noviet"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_collapse, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_collapse_item, {
                                disabled: !_ctx.noviet.pitch,
                                title: "Pitch"
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.noviet.pitch)
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 0,
                                        class: "noviet-text",
                                        innerHTML: _ctx.marked(_ctx.noviet.pitch)
                                      }, null, 8 /* PROPS */, _hoisted_1))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["disabled"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.$isMobile())
        ? (_openBlock(), _createBlock(_component_el_main, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_avatar, {
                fit: "cover",
                shape: "square",
                size: 250,
                src: `data:image/jpeg;base64, ${_ctx.noviet.image}`
              }, null, 8 /* PROPS */, ["src"]),
              _createVNode(_component_ProfileCardActions, { noviet: _ctx.noviet }, null, 8 /* PROPS */, ["noviet"]),
              _createVNode(_component_el_divider),
              _createVNode(_component_ProfileCardInfo, { noviet: _ctx.noviet }, null, 8 /* PROPS */, ["noviet"]),
              _createVNode(_component_el_collapse, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_collapse_item, {
                    disabled: !_ctx.noviet.pitch,
                    title: "Pitch"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.noviet.pitch)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: "noviet-text",
                            innerHTML: _ctx.marked(_ctx.noviet.pitch)
                          }, null, 8 /* PROPS */, _hoisted_2))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
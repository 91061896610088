import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';
import { Username } from '@schranz/models';

export async function postRole(requestData: {
  username: string;
  role: string;
}): Promise<Username> {
  const payload: RequestData = {
    method: 'post',
    url: `${SCHRANZ_API_URL}/forum/roles`,
    data: requestData,
  };

  const { data } = await request(payload);
  return data;
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_descriptions, {
              direction: "horizontal",
              column: 1,
              size: "default",
              gutter: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions_item, { label: "rating" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_rate, {
                      modelValue: _ctx.noviet.rating,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.noviet.rating) = $event)),
                      "allow-half": "",
                      disabled: "",
                      "show-score": "",
                      "score-template": `${Math.round(_ctx.noviet.rating * 10) / 10} (${
                _ctx.noviet.ratings
              })`
                    }, null, 8 /* PROPS */, ["modelValue", "score-template"])
                  ]),
                  _: 1 /* STABLE */
                }),
                (_ctx.noviet.group || ['mod', 'admin'].includes(_ctx.role))
                  ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
                      key: 0,
                      label: "status"
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.groupInputVisible)
                          ? (_openBlock(), _createBlock(_component_el_tag, {
                              key: 0,
                              class: _normalizeClass(['mod', 'admin'].includes(_ctx.role) ? 'groupTag' : ''),
                              type: _ctx.groupColors[_ctx.noviet.group],
                              round: "",
                              onClick: _ctx.showGroupInput
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.noviet.group), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class", "type", "onClick"]))
                          : (_openBlock(), _createBlock(_component_el_select, {
                              key: 1,
                              modelValue: _ctx.noviet.group,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.noviet.group) = $event)),
                              class: "m-2",
                              size: "small"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: group,
                                    label: group,
                                    value: group,
                                    onClick: _ctx.hideGroupInput
                                  }, null, 8 /* PROPS */, ["label", "value", "onClick"]))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"]))
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                (_ctx.noviet.events?.length || ['mod', 'admin'].includes(_ctx.role))
                  ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
                      key: 1,
                      label: "activiteiten"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noviet.events, (event) => {
                          return (_openBlock(), _createBlock(_component_el_tag, {
                            class: _normalizeClass(['mod', 'admin'].includes(_ctx.role) ? 'groupTag' : ''),
                            key: event,
                            type: "info",
                            round: "",
                            onClick: _ctx.showEventsInput
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(event), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "onClick"]))
                        }), 128 /* KEYED_FRAGMENT */)),
                        (_ctx.eventSelectVisible)
                          ? (_openBlock(), _createBlock(_component_el_select, {
                              key: 0,
                              modelValue: _ctx.eventsInput,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.eventsInput) = $event)),
                              class: "ml-1 w-20",
                              size: "small",
                              multiple: ""
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: event,
                                    label: event,
                                    value: event
                                  }, null, 8 /* PROPS */, ["label", "value"]))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
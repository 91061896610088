import { Noviet } from '@schranz/models';

export interface NovietenSort {
  label: string;
  exec: (novieten: Noviet[]) => Noviet[];
  disabled: boolean;
}

export default [
  {
    label: 'Smoelenboek',
    exec: (novieten: Noviet[]) => {
      return novieten;
    },
    disabled: false,
  },
  {
    label: 'Naam',
    exec: (novieten: Noviet[]) => {
      return novieten.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    disabled: false,
  },
  {
    label: 'Updated',
    exec: (novieten: Noviet[]) => {
      return novieten.sort(({ updated: a }, { updated: b }) => {
        return new Date(a) < new Date(b) ? 1 : 0;
      });
    },
    disabled: false,
  },
  {
    label: 'Rating',
    exec: (novieten: Noviet[]) => {
      return novieten.sort((a, b) => {
        return b.rating - a.rating;
      });
    },
    disabled: false,
  },
  {
    label: 'Lunches',
    exec: (novieten: Noviet[]) => {
      return novieten.sort((a, b) => {
        return b.lunches - a.lunches;
      });
    },
    disabled: false,
  },
] as NovietenSort[];

import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';
import { Lunch } from '@schranz/models';

export async function postLunch(novietId: number): Promise<Lunch> {
  const payload: RequestData = {
    method: 'post',
    url: `${SCHRANZ_API_URL}/forum/novieten/${novietId}/lunch`,
  };

  const { data } = await request(payload);

  return data;
}

export async function deleteLunch(novietId: number): Promise<void> {
  const payload: RequestData = {
    method: 'delete',
    url: `${SCHRANZ_API_URL}/forum/novieten/${novietId}/lunch`,
  };

  await request(payload);
}


import { Component } from '@vue/runtime-core';
import Comments from '../components/Comments.vue';
import ProfileCard from '../components/ProfileCard.vue';
import PullRefresh from 'pull-refresh-vue3';

export default {
  components: {
    Comments,
    ProfileCard,
    PullRefresh,
  },

  methods: {
    async onRefresh() {
      await this.$store.dispatch('comments/getComments', {
        novietId: this.$route.params.novietId,
      });
      this.loading = false;
    },
  },

  computed: {
    noviet() {
      return this.$store.state.novieten.novieten[this.$route.params.novietId];
    },
  },

  data() {
    return {
      loading: false,
      timer: null,
    };
  },
} as Component;

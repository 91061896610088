import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_ProfileCard = _resolveComponent("ProfileCard")!
  const _component_Comments = _resolveComponent("Comments")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_pull_refresh = _resolveComponent("pull-refresh")!

  return (_openBlock(), _createBlock(_component_pull_refresh, {
    modelValue: _ctx.loading,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
    "pulling-text": "Laad comments opnieuw",
    "loosing-text": "Laad comments opnieuw",
    "loading-text": "Wist je dat alles automatisch wordt geupdated?",
    "success-text": "Geladen!",
    onRefresh: _ctx.onRefresh
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            xs: 1,
            sm: 2,
            md: 4,
            lg: 6,
            xl: 8
          }),
          _createVNode(_component_el_col, {
            xs: 22,
            sm: 20,
            md: 18,
            lg: 12,
            xl: 8
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_container, { id: "novietContainer" }, {
                default: _withCtx(() => [
                  (_ctx.noviet)
                    ? (_openBlock(), _createBlock(_component_el_card, {
                        key: 0,
                        id: "noviet-card"
                      }, {
                        header: _withCtx(() => [
                          _createVNode(_component_ProfileCard, { noviet: _ctx.noviet }, null, 8 /* PROPS */, ["noviet"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_Comments, { noviet: _ctx.noviet }, null, 8 /* PROPS */, ["noviet"])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_col, {
            xs: 1,
            sm: 2,
            md: 4,
            lg: 6,
            xl: 8
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onRefresh"]))
}
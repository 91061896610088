import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateNoviet = _resolveComponent("UpdateNoviet")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_UpdateNoviet, {
      visible: _ctx.updateNovietVisible,
      noviet: _ctx.updateNovietId
    }, null, 8 /* PROPS */, ["visible", "noviet"]),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 1,
          sm: 2,
          md: 4,
          lg: 6,
          xl: 8
        }),
        _createVNode(_component_el_col, {
          xs: 22,
          sm: 20,
          md: 18,
          lg: 12,
          xl: 8
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_container, { id: "manageContainer" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_card, { id: "manage-card" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tabs, {
                      modelValue: _ctx.activeTab,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
                      class: "tabs",
                      "tab-position": "top"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tab_pane, {
                          label: "Gebruikers",
                          name: "Users",
                          class: "tabs-item"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.roles)
                              ? (_openBlock(), _createBlock(_component_el_table, {
                                  key: 0,
                                  data: _ctx.roles
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_table_column, {
                                      label: "",
                                      width: 60
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createVNode(_component_el_avatar, {
                                          shape: "square",
                                          size: 40,
                                          src: `https://robohash.org/${scope.row.username}`,
                                          lazy: ""
                                        }, null, 8 /* PROPS */, ["src"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      prop: "username",
                                      label: "Username",
                                      width: "100"
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      label: "Role",
                                      width: "100"
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createVNode(_component_el_select, {
                                          modelValue: scope.row.role,
                                          "onUpdate:modelValue": ($event: any) => ((scope.row.role) = $event),
                                          class: "m-2",
                                          size: "small",
                                          onChange: _ctx.onRoleChange
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['lid', 'nestor', 'mod', 'admin'], (role) => {
                                              return _createVNode(_component_el_option, {
                                                key: `${scope.row.username}-${role}`,
                                                label: role,
                                                value: `${scope.row.username}-${role}`
                                              }, null, 8 /* PROPS */, ["label", "value"])
                                            }), 64 /* STABLE_FRAGMENT */))
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "onChange"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_el_table_column)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["data"]))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_el_tab_pane, {
                          label: "Novieten",
                          name: "Novieten",
                          class: "tabs-item"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.novieten)
                              ? (_openBlock(), _createBlock(_component_el_table, {
                                  key: 0,
                                  data: _ctx.novieten
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_table_column, {
                                      label: "",
                                      width: 60
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createVNode(_component_el_avatar, {
                                          shape: "square",
                                          size: 40,
                                          src: `data:image/jpeg;base64, ${scope.row.image}`,
                                          lazy: ""
                                        }, null, 8 /* PROPS */, ["src"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      prop: "name",
                                      label: "Name",
                                      width: "200"
                                    }),
                                    _createVNode(_component_el_table_column, {
                                      prop: "rating",
                                      label: "Rating",
                                      width: "75"
                                    }),
                                    _createVNode(_component_el_table_column),
                                    _createVNode(_component_el_table_column, { width: "75" }, {
                                      default: _withCtx((scope) => [
                                        _createVNode(_component_el_button, {
                                          type: "info",
                                          icon: "edit",
                                          onClick: ($event: any) => (_ctx.updateNoviet(scope.row.id))
                                        }, null, 8 /* PROPS */, ["onClick"])
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["data"]))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_col, {
          xs: 1,
          sm: 2,
          md: 4,
          lg: 6,
          xl: 8
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
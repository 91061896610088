import { CommentTableData, UserStats } from '@schranz/models';
import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';

export async function getUserStats(): Promise<UserStats> {
  const payload: RequestData = {
    method: 'get',
    url: `${SCHRANZ_API_URL}/forum/user/stats`,
  };

  const { data } = await request(payload);

  return data;
}

export async function getUserComments(): Promise<CommentTableData[]> {
  const payload: RequestData = {
    method: 'get',
    url: `${SCHRANZ_API_URL}/forum/user/comments`,
  };

  const { data } = await request(payload);

  return data;
}


import { Component } from '@vue/runtime-core';
import { mapState } from 'vuex';
import { SCHRANZ_API_URL } from '../config';
import filters, { NovietenFilter } from '../composables/novietenFilter';
import sorts, { NovietenSort } from '../composables/novietenSort';
import PullRefresh from 'pull-refresh-vue3';
import { Noviet } from '../models/Noviet';

export default {
  components: {
    PullRefresh,
  },

  methods: {
    selectNoviet(noviet: Noviet) {
      this.$router.push(`/noviet/${noviet.novietId}`);
    },
    async onRefresh() {
      await this.$store.dispatch('novieten/updateNovieten');
      this.loading = false;
    },
    getFilterAmount(filterLabel: string) {
      return (
        this.filters
          .find((filter: NovietenFilter) => filter.label === filterLabel)
          ?.exec(JSON.parse(JSON.stringify(this.novieten)))?.length ?? 0
      );
    },
  },

  watch: {
    sort(newValue) {
      window.localStorage.setItem('schranz_novieten_sort', newValue);
    },
    filter(newValue) {
      window.sessionStorage.setItem('schranz_novieten_filter', newValue);
    },
  },

  computed: {
    ...mapState('novieten', ['novieten']),
    parsedNovieten: function () {
      const toParse = JSON.parse(JSON.stringify(this.novieten));

      const sorts = this.sorts as NovietenSort[],
        filters = this.filters as NovietenFilter[];

      let sort = sorts.find((sort: NovietenSort) => sort.label === this.sort);
      if (!sort) {
        sort = sorts[0];
        this.sort = sort.label;
      }

      let filter = filters.find(
        (filter: NovietenFilter) => filter.label === this.filter
      );
      if (!filter || !filter.exec(toParse).length) {
        filter = filters[0];
        this.filter = filter.label;
      }

      const sortedNovieten = sort.exec(toParse || []);
      const filteredNovieten = filter.exec(sortedNovieten);

      return filteredNovieten;
    },
    novietenRows: function () {
      const rows: Noviet[][] = [];
      for (let i = 0; i < this.parsedNovieten.length; i += 24 / this.span)
        rows.push(this.parsedNovieten.slice(i, i + 24 / this.span));
      return rows;
    },
  },

  data() {
    return {
      SCHRANZ_API_URL,
      span: this.$isMobile() ? 12 : 3,
      loading: false,
      filterOptions: filters,
      sortOptions: sorts,
      filters,
      sorts,
      filter:
        window.sessionStorage.getItem('schranz_novieten_filter') ??
        filters[0].label,
      sort:
        window.localStorage.getItem('schranz_novieten_sort') ?? sorts[0].label,
      ratingColors: {
        1: '#ad3636',
        2: '#dfae27',
        3: '#dde00b',
        4: '#afe223',
        5: '#85e261',
      },
      groupColors: {
        afgevallen: 'danger',
        slotje: 'danger',
        'afschrijven te vroeg': 'warning',
        prima: 'primary',
        enthousiast: 'success',
        'te weinig informatie': 'info',
      },
    };
  },
} as Component;

const elements = document.getElementsByClassName('focus');
for (const element of Array.from(elements)) (element as any).focus();
// STYLE

import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';
import { Noviet } from '@schranz/models';

export async function getNovieten(): Promise<Noviet[]> {
  const payload: RequestData = {
    method: 'get',
    url: `${SCHRANZ_API_URL}/forum/novieten`,
  };

  const { data } = await request(payload);
  if (!data) {
    console.error(`[Novieten] expected to receive novieten`, data);
    throw new Error(`[Novieten] expected to receive novieten.`);
  }

  return data;
}


import { Component } from '@vue/runtime-core';
import { mapState } from 'vuex';
import { getUserStats, getUserComments } from '../composables/user';
import { marked } from 'marked';
import { logout } from '../composables/logout';

export default {
  computed: {
    ...mapState('authorization', ['user', 'role']),
    ...mapState('novieten', ['novieten']),
  },

  mounted() {
    getUserStats().then((stats) => {
      this.stats = stats;
    });
    getUserComments().then((comments) => {
      this.comments = comments;
    });
    if (!this.novieten.length) {
      this.$store.dispatch('novieten/getNovieten');
    }
  },

  methods: {
    getMarked(text: string) {
      return marked(text);
    },
    getNovietName(novietId: number) {
      return this.novieten[novietId].name;
    },
    getNovietImage(novietId: number) {
      return this.novieten[novietId].image;
    },
    onLogout() {
      logout();
    },
  },

  data() {
    return {
      stats: null as unknown as Awaited<ReturnType<typeof getUserStats>>,
      comments: null as unknown as Awaited<ReturnType<typeof getUserComments>>,
    };
  },
} as Component;

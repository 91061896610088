import { Module } from 'vuex';
import { getUser } from '../composables/getUser';
import { login, LoginData } from '../composables/login';
import router from '../router/router';
import { getRole } from '../composables/getRole';

export default {
  namespaced: true,

  state: {
    user: null,
    role: null,
    loginError: null,
    loggedIn: false,
    loginAttempt: null as null | Date,
    serverError: false,
  },

  mutations: {
    setUser(state, { user }) {
      state.user = user;
    },

    setRole(state, { role }) {
      state.role = role;
    },

    loginSuccess(state) {
      state.loggedIn = true;
      state.loginError = null;
      state.loginAttempt = new Date();
    },

    loginFailure(state, { error }) {
      state.loggedIn = false;
      state.loginError = error;
      state.loginAttempt = new Date();
    },

    serverError(state, { error }) {
      state.serverError = error;
    },
  },

  actions: {
    async checkJWT({ commit }) {
      console.log('[Store] checkJWT triggered');
      try {
        const [user, role] = await Promise.all([getUser(), getRole()]);
        console.log('[Store] JWT success');
        commit('loginSuccess');
        commit('setUser', {
          user,
        });
        commit('setRole', {
          role,
        });
      } catch (err) {
        console.log('[Store] JWT failure', err);
        commit('loginFailure', {});
      }
    },

    async login({ commit }, loginData: LoginData) {
      console.log('[Store] login triggered');
      try {
        await login(loginData);
        console.log('[Store] login success');
        commit('loginSuccess');
        router.go(0);
      } catch (err: any) {
        console.error('[Store] login failure', err);
        commit('loginFailure', {
          error: err.message,
        });
      }
    },
  },
} as Module<any, any>;

import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    fullscreen: _ctx.$isMobile(),
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    title: "Login",
    "show-close": false,
    "close-on-press-escape": false,
    "close-on-click-modal": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.form,
        "label-width": "auto",
        "label-position": "left",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createCommentVNode(" Email input "),
          _createVNode(_component_el_form_item, {
            label: "Gebruikersnaam",
            prop: "username"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "username",
                id: "formUsername",
                modelValue: _ctx.form.username,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
                onKeyup: _withKeys(_ctx.signin, ["enter"])
              }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
            ]),
            _: 1 /* STABLE */
          }),
          _createCommentVNode(" Password input "),
          _createVNode(_component_el_form_item, {
            label: "Wachtwoord",
            prop: "password"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "password",
                id: "formPassword",
                modelValue: _ctx.form.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
                onKeyup: _withKeys(_ctx.signin, ["enter"])
              }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
            ]),
            _: 1 /* STABLE */
          }),
          _createCommentVNode(" 2 column grid layout for inline styling "),
          _createVNode(_component_el_form_item, { label: "Onthoud mij" }, {
            default: _withCtx(() => [
              _createCommentVNode(" Checkbox "),
              _createVNode(_component_el_checkbox, {
                checked: "",
                id: "formRemember",
                modelValue: _ctx.form.remember,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.remember) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }),
          _createCommentVNode(" Submit button "),
          _createVNode(_component_el_form_item, { justify: "space-evenly" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: _ctx.$isMobile() ? 24 : 4
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    block: "",
                    onClick: _withModifiers(_ctx.signin, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Inloggen")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["span"]),
              _createVNode(_component_el_col, {
                span: _ctx.$isMobile() ? 24 : 4
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "info",
                    href: "#!",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showForgotPassword = true))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Wachtwoord vergeten?")
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["span"])
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.loginErrorMessage)
            ? (_openBlock(), _createBlock(_component_el_alert, {
                key: 0,
                type: "error"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.loginErrorMessage), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["fullscreen", "modelValue"]))
}

import { Component } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { marked } from 'marked';

const confirmChangeEdit = (confirmFunction: any, cancelFunction: any) => {
  ElMessageBox.confirm(
    'Je bent al een andere comment aan het editen, wil je doorgaan?',
    'Let op!',
    {
      confirmButtonText: 'Doorgaan',
      cancelButtonText: 'Annuleer',
      type: 'warning',
    }
  )
    .then(confirmFunction)
    .catch(cancelFunction);
};

export default {
  computed: {
    editing() {
      return this.$store.state.comments.comments[this.noviet.novietId].editing;
    },
  },

  methods: {
    edit() {
      if (this.editing && this.editing !== this.comment.id) {
        const cancelFunction = () =>
          ElMessage({
            message: 'Bewerken gestopt.',
            type: 'info',
          });
        confirmChangeEdit(this.startEdit, cancelFunction);
      } else {
        this.startEdit();
      }
    },
    startEdit() {
      this.$store.commit('comments/setEditing', {
        novietId: this.noviet.novietId,
        commentId: this.comment.id,
      });
    },
    async editConfirm() {
      if (!this.form.newComment.length) return;
      try {
        await this.$store.dispatch('comments/patchComment', {
          commentId: this.comment.id,
          comment: this.form.newComment,
          novietId: this.noviet.novietId,
          rating: this.form.newRating,
        });
        ElMessage({
          message: 'Je bericht is geupdate!',
          type: 'success',
        });
      } catch (err) {
        ElMessageBox.alert((err as any).message, 'Fout tijdens opslaan', {
          confirmButtonText: 'oke',
        });
      }
    },
    editCancel() {
      this.$store.commit('comments/setEditing', {
        novietId: this.noviet.novietId,
        commentId: 0,
      });
    },
    getMarked(comment: string) {
      return marked(comment);
    },
  },

  watch: {
    editing(newValue) {
      if (newValue === this.comment.id) {
        this.form.newComment = this.comment.comment;
        this.form.newRating = this.comment.rating;
        this.isEditing = true;
      } else {
        this.isEditing = false;
        this.form.newComment = '';
        this.form.newRating = 0;
      }
    },
  },

  unmounted() {
    this.editCancel();
  },

  props: ['comment', 'noviet'],

  data() {
    return {
      isEditing: false,
      rules: {
        newComment: [
          { required: true, message: 'Je kan niet niets commenten!' },
        ],
      },
      form: {
        newComment: '',
        newRating: 0,
      },
      unread: this.comment.isUnread,
      ratingColors: {
        1: '#ad3636',
        2: '#dfae27',
        3: '#dde00b',
        4: '#afe223',
        5: '#85e261',
      },
    };
  },
} as Component;

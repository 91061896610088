
import { Component } from 'vue';
import { mapState } from 'vuex';
import Header from './components/Header.vue';
import Login from './components/Login.vue';
import { getJWT } from './composables/getUser';
import { onMessage } from './composables/onMessage';
import { SCHRANZ_WS_URL } from './config';

export default {
  name: 'App',
  components: {
    Header,
    Login,
  },

  async beforeMount() {
    console.log('[App] checking jwt');
    if (!this.$store.state.authorization.loggedIn)
      await this.$store.dispatch('authorization/checkJWT');
    else this.startWebsocket();
    console.log('[App] checked jwt');
    console.log(`Mobile: ${this.$isMobile()}`);
  },

  computed: {
    ...mapState('authorization', ['loggedIn', 'user']),
    serverError() {
      return this.$store.state.authorization.serverError;
    },
  },

  methods: {
    startWebsocket() {
      if (this.connection) return;
      this.connection = new WebSocket(SCHRANZ_WS_URL);
      this.connection.onmessage = (message: MessageEvent) => {
        try {
          const data = JSON.parse(message.data);
          console.log('[App] Message received', data);
          onMessage(data);
        } catch (error) {
          console.error(error);
        }
      };
      this.connection.onopen = () => {
        console.log('[App] Connection opened');
        this.connection.send(
          JSON.stringify({
            type: 'auth',
            payload: {
              jwt: getJWT(),
            },
          })
        );
      };
      this.connection.onclose = () => {
        console.log('[App] Connection closed');
        this.connection = null as unknown as WebSocket;
        this.startWebsocketTimeout();
      };
    },
    startWebsocketTimeout() {
      try {
        this.startWebsocket();
        this.$store.dispatch('novieten/updateNovieten');
        const noviet = this.$route.params?.novietId;
        if (noviet)
          this.$store.dispatch('comments/getComments', {
            novietId: noviet,
          });
      } catch (err) {
        console.error(`[App] Error starting websocket: ${err}`);
        this.connection = null as unknown as WebSocket;
        setTimeout(() => {
          this.startWebsocketTimeout();
        }, 10000);
      }
    },
  },

  watch: {
    loggedIn(newValue) {
      if (newValue === true) {
        this.$store.dispatch('novieten/updateNovieten');
        this.startWebsocket();
      } else {
        this.connection?.close();
      }
      if (newValue === false) this.$router.go(1);
    },
  },

  data() {
    return {
      novietenTimer: null as unknown as NodeJS.Timer,
      connection: null as unknown as WebSocket,
    };
  },
} as Component;

import { Module } from 'vuex';
import {
  getComments,
  patchComment,
  postComment,
} from '../composables/comments';

export default {
  namespaced: true,

  state: {
    comments: {},
  },

  mutations: {
    setComments(state, { novietId, comments }) {
      if (!state.comments[novietId]) {
        state.comments[novietId] = {
          comments: [],
          editing: 0,
        };
      }
      state.comments[novietId].comments = comments;
    },

    setEditing(state, { novietId, commentId }) {
      state.comments[novietId].editing = commentId;
    },
  },

  actions: {
    async getComments({ commit, state }, { novietId }) {
      if (state.comments[novietId] && state.comments[novietId].editing) return;
      console.log('[Store] get comments triggered');
      try {
        const comments = await getComments(novietId);
        commit('setComments', { novietId, comments });
      } catch (err) {
        console.error('[Store] get comments error', err);
      }
    },

    async postComment({ dispatch }, { novietId, comment, rating }) {
      console.log(`[Store] posting comment ${novietId}`);
      try {
        await postComment(novietId, comment, rating);
        dispatch('getComments', { novietId });
      } catch (err) {
        console.error(`[Store] posting comment`, err);
        throw err;
      }
    },

    async patchComment(
      { dispatch, commit },
      { novietId, commentId, comment, rating }
    ) {
      console.log(`[Store] editing comment ${commentId}`);
      try {
        await patchComment(commentId, comment, rating);
        commit('setEditing', { novietId, commentId: 0 });
        dispatch('getComments', { novietId });
      } catch (err) {
        console.error(`[Store] editing comment`, err);
        throw err;
      }
    },
  },
} as Module<any, any>;


import { Component } from '@vue/runtime-core';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('authorization', ['user']),
    locked: {
      get() {
        return this.noviet.locked;
      },
      set(newValue) {
        this.$store.dispatch('novieten/lock', {
          novietId: this.noviet.novietId,
          toLock: newValue,
        });
      },
    },
    lunch: {
      get() {
        return this.noviet.lunch;
      },
      set(newValue) {
        this.$store.dispatch('novieten/lunch', {
          novietId: this.noviet.novietId,
          toLunch: newValue,
        });
      },
    },
    favourite: {
      get() {
        return this.noviet.favourite;
      },
      set(newValue) {
        this.$store.dispatch('novieten/favourite', {
          novietId: this.noviet.novietId,
          toFavourite: newValue,
        });
      },
    },
    useSpoon() {
      if (this.spoons.includes(this.user.username)) return true;
      if (this.knives.includes(this.user.username)) return false;
      else return Math.random() < 0.5;
    },
  },

  props: ['noviet'],

  watch: {},

  methods: {},

  data() {
    return {
      spoons: ['doris, laura, enzo, po, osaro, sarah, merel'],
      knives: ['mitchell, hilde, aicha, sander, sophie, murre, floor'],
    };
  },
} as Component;

import { createRouter, createWebHashHistory } from 'vue-router';
import Novieten from '../views/Novieten.vue';
import Noviet from '../views/Noviet.vue';
import Manage from '../views/Manage.vue';
import User from '../views/User.vue';

const routes = [
  {
    path: '/',
    name: 'Novieten',
    component: Novieten,
  },
  {
    path: '/noviet/:novietId',
    name: 'Noviet',
    component: Noviet,
  },
  {
    path: '/manage',
    name: 'Manage',
    component: Manage,
  },
  {
    path: '/user',
    name: 'User',
    component: User,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, saved) {
    return {
      left: 0,
      top: saved?.top ?? 0,
    };
  },
});

export default router;

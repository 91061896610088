
import { Component } from '@vue/runtime-core';
import { mapState } from 'vuex';
import { getRoles } from '../composables/getRoles';
import { postRole } from '../composables/postRole';
import UpdateNoviet from '../components/UpdateNoviet.vue';

export default {
  computed: {
    ...mapState('authorization', ['user']),
    ...mapState('novieten', ['novieten']),
  },

  mounted() {
    getRoles().then((roles) => {
      this.roles = roles;
    });
  },

  methods: {
    onRoleChange(usernameAndRole: string) {
      return postRole({
        username: usernameAndRole.split('-')[0],
        role: usernameAndRole.split('-')[1],
      });
    },
    updateNoviet(novietId: number) {},
  },

  data() {
    return {
      activeTab: 'Users',
      roles: [] as any[],
      updateNovietId: null,
      updateNovietVisible: false,
    };
  },
} as Component;

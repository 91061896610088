
import { Component } from '@vue/runtime-core';
import ProfileCardInfo from './ProfileCardInfo.vue';
import ProfileCardActions from './ProfileCardActions.vue';
import { marked } from 'marked';

export default {
  components: {
    ProfileCardActions,
    ProfileCardInfo,
  },

  methods: {
    marked(text: string) {
      return marked(text.replace(/\n/g, '<br>'));
    },
  },

  props: ['noviet'],

  watch: {},

  data() {
    return {
      ratingTexts: [
        'Afschrijven',
        'Kut noviet',
        'Blijft een noviet',
        'Wel leuk',
        'Om nom nom nom!',
      ],
    };
  },
} as Component;

import { useAxios } from '@vue-composable/axios';
import { store } from '../main';
import { getJWT } from './getUser';

export interface RequestData {
  method: 'get' | 'post' | 'patch' | 'delete';
  url: string;
  data?: Record<string, unknown>;
  headers?: Record<string, string>;
}

export interface Result {
  data: any;
  status: number | null;
  error?: any;
}

export async function request(request: RequestData): Promise<Result> {
  const { exec, data, status, error } = useAxios(false);
  const jwt = getJWT();
  if (jwt)
    request.headers
      ? (request.headers.Authorization = `Bearer ${jwt}`)
      : (request.headers = { Authorization: `Bearer ${jwt}` });

  console.log(`[request] sending request ${request.url}`);
  await exec(request);
  console.log(`[request] finished request ${request.url}`);

  const result = {
    data: data.value,
    status: status.value,
    error: error.value,
  };

  if (result.status == 401) {
    console.log(
      '[request] committing loginFailure',
      result.status,
      result.data,
      result.error
    );
    store.commit('authorization/loginFailure', {
      error: result.data?.message,
    });
    throw new Error(result.data?.message);
  } else if (
    result.error &&
    result.status &&
    result.status >= 400 &&
    result.status < 500
  ) {
    console.error(`[request] error ${result.status}`, result.error);

    throw error;
  } else if (result.error || (result.status && result.status >= 500)) {
    console.log('[request] committing serverError', result.status);

    store.commit('authorization/serverError', {
      error: true,
    });
  } else {
    store.commit('authorization/serverError', {
      error: false,
    });
  }

  return result;
}


import { Component } from 'vue';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('authorization', ['user', 'role']),
  },

  methods: {
    handleSelect(key: string) {
      if (key === '/') this.$router.push('/');
      if (key === 'manage') this.$router.push('/manage');
      if (key === 'user') this.$router.push('/user');
    },
  },
} as Component;

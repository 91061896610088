import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';
import { NovietStatic } from '@schranz/models';

export async function patchGroup(
  novietId: number,
  group: string
): Promise<NovietStatic> {
  const payload: RequestData = {
    method: 'patch',
    url: `${SCHRANZ_API_URL}/forum/novieten/${novietId}/group`,
    data: {
      group,
    },
  };

  const { data } = await request(payload);

  return data;
}

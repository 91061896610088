import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';
import { NovietStatic } from '@schranz/models';

export async function postEvent(
  novietId: number,
  event: string
): Promise<NovietStatic> {
  const payload: RequestData = {
    method: 'post',
    url: `${SCHRANZ_API_URL}/forum/novieten/${novietId}/event`,
    data: {
      event,
    },
  };

  const { data } = await request(payload);

  return data;
}

export async function deleteEvent(
  novietId: number,
  event: string
): Promise<NovietStatic> {
  const payload: RequestData = {
    method: 'delete',
    url: `${SCHRANZ_API_URL}/forum/novieten/${novietId}/event`,
    data: {
      event,
    },
  };

  const { data } = await request(payload);

  return data;
}

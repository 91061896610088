
import { FormRules } from 'element-plus';
import { Component, reactive, ref } from 'vue';
import { mapState } from 'vuex';

export default {
  setup() {
    const form = reactive({
      username: '',
      password: '',
      remember: false,
    });
    const dialogVisible = ref(false);
    const signinError = ref('');

    return {
      form,
      dialogVisible,
      signinError,
    };
  },

  beforeMount() {
    if (
      this.$store.state.authorization.loginAttempt &&
      !this.$store.state.authorization.loggedIn
    )
      this.dialogVisible = true;
  },

  computed: {
    ...mapState('authorization', ['loginAttempt, loggedIn, loginError']),
  },

  watch: {
    '$store.state.authorization.loginAttempt': function (newValue) {
      console.log(`[Login] New login attempt: ${newValue}`);
      const loggedIn = this.$store.state.authorization.loggedIn;
      if (loggedIn === false) this.dialogVisible = true;
      else {
        (this.form.username = ''),
          (this.form.password = ''),
          (this.dialogVisible = false);
      }
    },

    '$store.state.authorization.loginError': function (newValue) {
      if (newValue === undefined || newValue === null)
        return (this.loginErrorMessage = null);
      if (typeof newValue === 'string') {
        if (newValue.includes('User not found'))
          return (this.loginErrorMessage = 'Gebruiker niet gevonden');
        if (newValue.includes('Wrong password'))
          return (this.loginErrorMessage = 'Verkeerd wachtwoord');
        if (newValue.includes('No authorization header'))
          return (this.loginErrorMessage = null);
      }
      this.loginErrorMessage = newValue;
    },
  },

  methods: {
    async signin() {
      await this.$store.dispatch('authorization/login', this.form);
    },
  },

  data() {
    return {
      showForgotPassword: false,
      loginErrorMessage: null,
      rules: reactive<FormRules>({
        username: [
          {
            required: true,
            message: 'Vul je Schranz gebruikersnaam in',
            trigger: 'blur',
          },
          {
            min: 2,
            message: 'Je gebruikersnaam is je voornaam',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message:
              'Vul je wachtwoord in, deze is hetzelfde als je fotoalbum wachtwoord',
            trigger: 'blur',
          },
        ],
      }),
    };
  },
} as Component;
